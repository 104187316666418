import { tlang } from '@softtech/webmodule-components';
import { Snippet, PromiseTemplate } from '@softtech/webmodule-data-contracts';
import { html } from 'lit-html';

import { isAutoSaving } from '../../components/save-workflow';
import { PageControlTabWithIndependantSaving } from '../../components/ui/data-entry-screen-base';
import { DataTracker, FieldType } from '../../components/ui/databinding/data-tracker';
import { DataBinding } from '../../components/ui/databinding/databinding';
import { FormInputAssistant } from '../../components/ui/templateresult/form-input-assistant';

import { fireQuickSuccessToast } from '../../toast-away';
import { isEmptyOrSpace } from '../../components/ui/helper-functions';
import { FileContainer, FileContainerManager } from '../data/file-content-container';
import { Editor } from '@toast-ui/editor';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { stringToBase64 } from '../../blob/converters';
import { SupplierSettings } from '../../api/supplier-api-interface-supplier';

export class SettingsGeneralView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataBinding: DataBinding;
  private dataTracker: DataTracker;

  private termsAndContitionsManager: FileContainerManager;
  private editor?: Editor;

  constructor() {
    super();
    this.pageFragment = 'general';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.termsAndContitionsManager = new FileContainerManager(
      new FileContainer(this.settingsManager.supplierSettings.termsAndConditionsVirtualPath),
      () => tlang`Terms & Conditions`
    );
    this.termsAndContitionsManager.afterSave.push(async () => {
      this.settingsManager.updateInput.base64TermsAndConditions = stringToBase64(
        this.termsAndContitionsManager.fileContent
      );
      await this.settingsManager.saveSettings();
      await this.render();
    });
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.settings),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('validityPeriod', FieldType.int);
    addField('leadPeriod', FieldType.int);
  }

  public async afterConstruction(): Promise<void> {
    await this.render();
  }

  public allowDeletePage(): boolean {
    return false;
  }
  protected getCaption(): Snippet {
    return tlang`General Settings`;
  }
  getValidationErrors(): string[] {
    const errors: string[] = [];

    const validityPeriod = this.dataBinding.getInt('validityPeriod');
    const leadPeriod = this.dataBinding.getInt('leadPeriod');
    const tacText = this.editor?.getMarkdown();

    if (validityPeriod == null || validityPeriod < 0) {
      errors.push(tlang`Validity Period must be greater than or equal to 0`);
    }

    if (leadPeriod == null || leadPeriod < 0) {
      errors.push(tlang`Lead Period must be greater than or equal to 0`);
    }

    if (isEmptyOrSpace(tacText)) {
      errors.push(tlang`No Terms And Condition specified`);
    }

    return errors;
  }

  async prepareForSave(): Promise<void> {
    if (this.dataTracker.modified) {
      this.dataTracker.applyChangeToValue();
    }

    this.termsAndContitionsManager.fileContent = this.editor?.getMarkdown() ?? '';
  }
  public internalDataChanged(): boolean {
    return this.settingsManager.changed() || this.termsAndContitionsManager.changed();
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);

    await this.termsAndContitionsManager.needsFile();

    const element = document.querySelector('#generalSettingsEditor') as HTMLElement | null;
    if (element) {
      this.editor = new Editor({
        el: element,
        previewStyle: 'vertical',
        height: '500px',
        initialValue: this.termsAndContitionsManager.fileContent,
        usageStatistics: false,
        initialEditType: 'wysiwyg'
      });
    }

    await this.render();
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  protected async internalSaveData(): Promise<boolean> {
    await this.termsAndContitionsManager.save(true);

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`${this.getCaption()} saved`);
      await this.render();
      return true;
    }

    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const forms = new FormInputAssistant(this.dataTracker);
    const toggleCustomItem = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      this.settings.allowCustomItem = e.detail;
    };

    const toggleItemInArray = (array: number[], item: number, add: boolean) => {
      if (add) {
        if (!array.includes(item)) {
          array.push(item);
        }
      } else {
        const index = array.indexOf(item);
        if (index !== -1) {
          array.splice(index, 1);
        }
      }
    };

    const toggleEstimateType = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      toggleItemInArray(this.settings.allowedQuoteTypes, 1, e.detail);
    };

    const toggleDisplaySampleType = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      toggleItemInArray(this.settings.allowedQuoteTypes, 4, e.detail);
    };

    const toggleWarrantyItemType = (e: CustomEvent<boolean>) => {
      e.stopImmediatePropagation();
      toggleItemInArray(this.settings.allowedQuoteTypes, 8, e.detail);
    };

    return html` <div>
      <form id="formGeneralSettings" class="form-one-col">
        <h2>${tlang`Quote Settings`}:</h2>
        <div class="row">
          <div class="col-md-6 form-column">
            ${forms.intRequired('validityPeriod', tlang`Validity Period (days)`, 0, 365)}
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-column">${forms.intRequired('leadPeriod', tlang`Lead Period (weeks)`, 0, 52)}</div>
        </div>

        <div class="row">
          <div class="col-md-6 form-column">
            <div class="row mb-2 align-items-center form-col-item">
              <label class="form-col-label">Supported Quote Types: </label>

              <div class="form-col-input">
                <bs-form-checkbox
                  data-label=${tlang`%%estimate%%`}
                  data-id=${this.dataBinding.field('estimate')}
                  .checked=${this.settings.allowedQuoteTypes.includes(1)}
                  display="inline-checkbox"
                  @checkbox-changed=${toggleEstimateType}
                >
                </bs-form-checkbox>
                <bs-form-checkbox
                  data-label=${tlang`%%display-sample%%`}
                  data-id=${this.dataBinding.field('displaysample')}
                  .checked=${this.settings.allowedQuoteTypes.includes(4)}
                  display="inline-checkbox"
                  @checkbox-changed=${toggleDisplaySampleType}
                >
                </bs-form-checkbox>
                <bs-form-checkbox
                  data-label=${tlang`%%warranty-item%%`}
                  data-id=${this.dataBinding.field('warrantyitem')}
                  .checked=${this.settings.allowedQuoteTypes.includes(8)}
                  display="inline-checkbox"
                  @checkbox-changed=${toggleWarrantyItemType}
                >
                </bs-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-column">
            <div class="row mb-2 align-items-center form-col-item">
              <label class="form-col-label">Allow Custom Item Request: </label>

              <div class="form-col-input">
                <bs-form-checkbox
                  data-id=${this.dataBinding.field('allowCustomItem')}
                  .checked=${this.settings.allowCustomItem}
                  @checkbox-changed=${toggleCustomItem}
                >
                </bs-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <h2>${tlang`%%purchase-order%% Terms & Conditions`}:</h2>
        <div class="row">
          <div id="generalSettingsEditor"></div>
        </div>
      </form>
    </div>`;
  }
}
