export enum DealerDeploymentOperationType {
  Production = 0,
  Test = 1
}
interface ResultBrandInfo {
  loginImageUrl: string;
  loginBrandLogo: string;
  footerBrandLogo: string;
  operationType: DealerDeploymentOperationType;
}

export function brandInfo(): ResultBrandInfo {
  return globalThis.dealerConfiguration.branding as ResultBrandInfo;
}

export function isTestSite(): boolean {
  return (brandInfo().operationType ?? DealerDeploymentOperationType.Production) === DealerDeploymentOperationType.Test;
}

export function loginImageUrl(): string {
  return brandInfo().loginImageUrl;
}
export function loginBrandLogoUrl(): string {
  return brandInfo().loginBrandLogo;
}
export function footerBrandLogoUrl(): string {
  return brandInfo().footerBrandLogo;
}
